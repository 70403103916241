<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import SolarComponentFunFacts from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentFunFacts.vue'
import SolarComponentQuestionInformation from '@/components/questions-paths-components/solar-path/solar-components/SolarComponentQuestionInformation.vue'

const store = useStore()
const router = useRouter()

const ANSWERS = [
  {
    label: 'Solar',
    route: '/solar-savings'
  },
  {
    label: 'Roofing',
    route: '/roofing'
  },
  {
    label: 'Windows',
    route: '/windows'
  },
  {
    label: 'Flooring',
    route: '/flooring'
  },
  {
    label: 'Remodel',
    route: '/remodel'
  }
]

function setPath(path) {
  const params = new URLSearchParams(location.search)
  const queryParams = Object.fromEntries(params.entries());
  store.commit('SET_IS_LOADING', true)
  router.push({ path, query: queryParams })
  store.commit('SET_IS_LOADING', false)
}
</script>

<template>
  <div class="questions-container">
    <div class="bg-image"></div>

    <div v-if="store.state.isLoading" class="component-loading" style="z-index: 1">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

    <div v-else class="question-flow">
      <div id="component-height" class="container">
        <div class="question-box">
          <h2 class="question">What type of project are you interested in?</h2>
          <div class="answers">
            <button
              v-for="answer in ANSWERS"
              @click="setPath(answer.route)"
            >
              {{ answer.label }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../assets/style/main';
.questions-container {
  .bg-image {
    background: linear-gradient(rgba(24, 70, 157, 0.85), rgba(24, 70, 157, 0.85)), url("../../assets/images/bg-small.jpg") no-repeat;
    background-size: cover;
  }
}
</style>
